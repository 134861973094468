import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ITemplate } from '/@/types/template-service';

export type EditingTemplate = Omit<ITemplate, 'document'>;
interface EditorState {
    selectedComponentId?: string;
    hiddenComponents?: string[];
    zoom: number;
    editingTemplate?: EditingTemplate;
    editorComponentsProps: Record<string, object>;
    editorComponentsErrors: Record<string, string[]>;
}

const initialState: EditorState = {
    zoom: 1,
    hiddenComponents: [],
    editorComponentsProps: {},
    editorComponentsErrors: {},
};

const editorSlice = createSlice({
    name: 'editor',
    initialState,
    reducers: {
        selectComponent: (state, action: PayloadAction<string | undefined>) => {
            state.selectedComponentId = action.payload;
        },
        performZoom: (state, action: PayloadAction<number>) => {
            state.zoom = action.payload;
        },
        setEditingTemplate: (state, action: PayloadAction<ITemplate>) => {
            const payload = action.payload;
            state.editingTemplate = {
                template_id: payload.template_id,
                document_version: payload.document_version,
                template_type_id: payload.template_type_id,
                template_type_name: payload.template_type_name,
                template_type_description: payload.template_type_description,
                template_type_default_properties: payload.template_type_default_properties,
                template_type_sample_data: payload.template_type_sample_data,
                subtype_name: payload.subtype_name,
                description: payload.description,
                sample_data: payload.sample_data,
            };
        },
        setTemplateId: (state, action: PayloadAction<string>) => {
            if (state.editingTemplate) {
                state.editingTemplate = {
                    ...state.editingTemplate,
                    template_id: action.payload,
                };
            }
        },
        setDocumentVersion: (state, action: PayloadAction<number>) => {
            if (state.editingTemplate) {
                state.editingTemplate = {
                    ...state.editingTemplate,
                    document_version: action.payload,
                };
            }
        },
        resetEditorState: () => {
            return initialState;
        },
        setEditorComponentProps: (state, action: PayloadAction<{ componentId: string; props: any }>) => {
            const { componentId, props } = action.payload;
            if (!state.editorComponentsProps) {
                state.editorComponentsProps = {};
            }
            state.editorComponentsProps[componentId] = {
                ...state.editorComponentsProps[componentId],
                ...props,
            };
        },
        setEditorComponentError: (state, action: PayloadAction<{ componentId: string; error: string }>) => {
            const { componentId, error } = action.payload;
            if (!Array.isArray(state.editorComponentsErrors[componentId])) {
                state.editorComponentsErrors[componentId] = [error];
            } else {
                state.editorComponentsErrors[componentId].push(error);
            }
        },
        clearEditorComponentErrors: (state, action: PayloadAction<{ componentId: string }>) => {
            const { componentId } = action.payload;
            if (state.editorComponentsErrors[componentId] && state.editorComponentsErrors[componentId].length) {
                state.editorComponentsErrors[componentId] = [];
            }
        },
        toggleComponentVisibility: (state, action: PayloadAction<{ componentId: string }>) => {
            const { payload } = action;
            if (!state.hiddenComponents) {
                state.hiddenComponents = [payload.componentId];
            } else if (state.hiddenComponents.indexOf(payload.componentId) > -1) {
                state.hiddenComponents = state.hiddenComponents.filter((id) => id !== payload.componentId);
            } else {
                state.hiddenComponents.push(payload.componentId);
            }
        },
    },
});

export const {
    selectComponent,
    performZoom,
    setEditingTemplate,
    setTemplateId,
    setDocumentVersion,
    resetEditorState,
    setEditorComponentProps,
    setEditorComponentError,
    clearEditorComponentErrors,
    toggleComponentVisibility,
} = editorSlice.actions;
export default editorSlice;
