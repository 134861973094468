import CssBaseline from '@material-ui/core/CssBaseline';
import {
    ThemeProvider as MuiThemeProvider,
    unstable_createMuiStrictModeTheme as createMuiTheme,
} from '@material-ui/core/styles';
import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';

type AvailableThemes = 'dark' | 'light';

const THEME_STORAGE_KEY = 'current-theme';

const ThemeNameContext = createContext({
    theme: getCurrentTheme(),
    toggleTheme: () => undefined as void,
});

export const getTheme = (themeType: AvailableThemes = getCurrentTheme()) =>
    createMuiTheme({
        palette: {
            type: themeType,
            primary: {
                main: '#4caf50',
            },
            secondary: {
                main: '#ff3d00',
            },
        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    'html, body, #root': {
                        width: '100%',
                        height: '100%',
                    },
                    '#root': {
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                    },
                },
            },
        },
    });

export function ThemeProvider({ children }: React.PropsWithChildren<{}>) {
    return (
        <ThemeNameContextProvider>
            <ThemeNameContext.Consumer>
                {({ theme }) => (
                    <MuiThemeProvider theme={getTheme(theme)}>
                        <CssBaseline />
                        {children}
                    </MuiThemeProvider>
                )}
            </ThemeNameContext.Consumer>
        </ThemeNameContextProvider>
    );
}

export function useCurrentTheme() {
    return useContext(ThemeNameContext);
}

function ThemeNameContextProvider({ children }: PropsWithChildren<{}>) {
    const [currentTheme, setTheme] = useState(getCurrentTheme);

    const toggleTheme = useCallback(
        () =>
            setTheme((th) => {
                const newTheme = th === 'dark' ? 'light' : 'dark';
                localStorage.setItem(THEME_STORAGE_KEY, newTheme);
                return newTheme;
            }),
        []
    );

    const contextValue = useMemo(
        () => ({
            theme: currentTheme,
            toggleTheme,
        }),
        [currentTheme, toggleTheme]
    );

    return <ThemeNameContext.Provider value={contextValue}>{children}</ThemeNameContext.Provider>;
}

function getCurrentTheme(): AvailableThemes {
    return (localStorage.getItem(THEME_STORAGE_KEY) as AvailableThemes) || getOSTheme();
}

function getOSTheme(): AvailableThemes {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
}
