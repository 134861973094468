import { IconButton, Avatar, Menu, MenuItem, Divider } from '@material-ui/core';

import copy from 'copy-to-clipboard';
import { useKeycloak } from '@react-keycloak/web';
import { useCurrentTheme } from '/@/theme/ThemeProvider';
import React, { useMemo, useState } from 'react';

type KeycloakParsedToken = {
    picture?: string;
    name?: { display?: string };
    email?: string;
};

export const AppBarProfile = () => {
    const { keycloak } = useKeycloak();
    const { theme, toggleTheme } = useCurrentTheme();

    const [anchorEl, setAnchorEl] = useState<HTMLElement>();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    const handleSignOut = () => keycloak.logout();

    const parsedToken = useMemo<KeycloakParsedToken | undefined>(
        () => keycloak.idTokenParsed as KeycloakParsedToken,
        [keycloak.idTokenParsed]
    );
    const productVersion =
        window.env.PRODUCT_VERSION && window.env.COMMIT_TAG
            ? `${window.env.PRODUCT_VERSION} (${window.env.COMMIT_TAG})`
            : process.env.REACT_APP_PRODUCT_VERSION || 'local';
    return (
        <React.Fragment>
            <div>
                <IconButton
                    aria-label='account of current user'
                    aria-controls='menu-appbar'
                    aria-haspopup='true'
                    onClick={handleMenu}
                    color='inherit'
                    size={'small'}
                >
                    <Avatar src={parsedToken?.picture} />
                </IconButton>
                <Menu
                    id='menu-appbar'
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={anchorEl !== undefined}
                    onClose={handleClose}
                >
                    {parsedToken?.name && <MenuItem onClick={handleClose}>{parsedToken.name?.display}</MenuItem>}
                    {parsedToken?.email && <MenuItem onClick={handleClose}>{parsedToken.email}</MenuItem>}
                    {(parsedToken?.name || parsedToken?.email) && <Divider />}
                    <MenuItem
                        onClick={() => {
                            toggleTheme();
                            handleClose();
                        }}
                    >{`${theme === 'light' ? 'Dark' : 'Light'} Mode`}</MenuItem>
                    <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
                    <MenuItem>{`Version: ${productVersion}`}</MenuItem>
                    {keycloak && keycloak.token && (
                        <MenuItem onClick={() => copy(keycloak.token || '')}>Copy Token</MenuItem>
                    )}
                </Menu>
            </div>
        </React.Fragment>
    );
};
